var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","justify":"center"}},[_c('v-flex',{attrs:{"xs12":"","md12":""}},[_c('v-card',{staticClass:"mx-auto mb-0 elevation-0",attrs:{"rounded":""}},[_c('offer-filters',{on:{"resetPagination":function($event){return _vm.resetPagination()}}}),_c('v-divider',{staticClass:"mx-5"}),_c('v-card-text',[(_vm.Offers.state.isTable)?_c('data-table',{ref:"table",attrs:{"headers":_vm.headers,"items":_vm.Offers.state.items,"items-per-page":_vm.Offers.state.rowsNumber,"server-items-length":_vm.Offers.state.total,"options":_vm.Offers.state.pagination,"loading":_vm.Offers.state.loading,"itemsNew":_vm.Offers.state.rowsNumber,"sortBy":_vm.Offers.state.options.sortBy,"sortDesc":_vm.Offers.state.options.descending,"hide-default-footer":""},on:{"update:options":function($event){return _vm.$set(_vm.Offers.state, "pagination", $event)},"paginate":function($event){return _vm.Offers.actions.applyOptions({ value: $event })}},scopedSlots:_vm._u([{key:"item.thumbnail",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center tdImg"},[(item.thumbnail)?_c('v-img',{attrs:{"src":("" + (item.thumbnail)),"width":"32px"}}):_vm._e()],1)]}},{key:"item._id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-regular transparent idText",attrs:{"router":"","to":{ name: 'Offers', params: { _offer: item._id } },"small":"","transparent":""},on:{"click":function($event){return _vm.copyToClipboard(("" + (item._id)))}}},[_c('v-icon',{staticClass:"copy"},[_vm._v(_vm._s(_vm.Icons.copyContent))]),_c('FormattedText',{staticClass:"transparent",attrs:{"value":item._id,"num":15}})],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('FormattedText',{attrs:{"value":item.name,"num":51}})]}},{key:"item.categories",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"categories"},[_c('FormattedText',{attrs:{"value":_vm.categoriesCharge(item),"num":23}})],1)]}},{key:"item.payoutTypes",fn:function(ref){
var item = ref.item;
return [_c('FormattedText',{attrs:{"value":_vm.payoutTypesCharge(item),"num":23}})]}},{key:"item.payout",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold payoutChip pl-1 pr-1",attrs:{"small":"","label":""},domProps:{"innerHTML":_vm._s(_vm.getPayoutField(item.payout, item.currency, item.hasEvents))}})]}},{key:"item.validatedStatus",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold",style:(_vm.colorStatus(item.validatedStatus)),attrs:{"small":"","label":""}},[_vm._v(_vm._s(item.validatedStatus))])]}},{key:"item.details",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2 text-capitalize",staticStyle:{"border":"1px solid #2196f3 !important"},attrs:{"depressed":"","small":"","label":"","color":"white lighten-1","textColor":"blue"},on:{"click":function($event){return _vm.selectItem(item)}}},[_vm._v("Details "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-right")])],1)]}}],null,false,2350147516)}):_c('data-list',{ref:"list",attrs:{"items":_vm.Offers.state.items,"rows-per-page":_vm.Offers.state.rowsNumberList,"server-items-length":_vm.Offers.state.total,"loading":_vm.Offers.state.loading,"itemsNewList":_vm.Offers.state.rowsNumberList,"sortBy":_vm.Offers.state.options.sortBy,"sortDesc":_vm.Offers.state.options.descending},on:{"paginate":function($event){return _vm.Offers.actions.applyOptions({ value: $event })}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-row',_vm._l((props.items),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"12","sm":"6","md":"6","lg":"4","xl":"3"}},[_c('offer-preview-grid',{key:item._id,attrs:{"item":item},on:{"select":function($event){return _vm.selectItem(item)}}})],1)}),1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }